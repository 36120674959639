#callTable {
    margin-top: 25px;
}

#errCodeTable {
    margin-top: 25px;
}

td, tr
{
    height: 50px; 
    width: 50px;
}

tr:hover {
    background-color: #FAFAFA;
}

.ant-table-cell {
    padding-left: 20px;
}
@primary-color: #1DA57A;