@blackmorti:#252525;
@redmorti:#f93737;
@greymorti:#ceccce;
@yapikredi:#1366B2;


.alotech-navbar {
  background-color: @yapikredi !important;
}
.alotech-logo {
  position: relative;
}

.alotech-logo .dv{
    width: 100px;
    position: relative;
    margin: auto;
    font-size: 1.9rem;
    font-weight: 700;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;

}



.site-page-header {
  border: 1px solid rgb(235, 237, 240);
}



.alotech-loading-container {
  width: 100%;
  height: calc(100vh - 140px);
  position: relative;
}
.alotech-loading-container-spinner {
  margin: 0 !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.infopage-url-row > .ant-col-20 >span{
  color: green
}

.infopage-desc-row > .ant-col-20 > p {
  font-style: italic;
}

@primary-color: #1DA57A;